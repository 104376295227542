<template>
  <a-modal
    id="tarce-page-video-modal"
    v-model:visible="showVideoModal"
    centered
    width="100%"
    wrap-class-name="full-modal inference-modal"
    destroy-on-close
    :footer="null"
    :body-style="{ padding: '6px 12px' }"
    @cancel="handleVideoModalClose"
  >
    <template #title>
      <div
        class="d-flex justify-content-between align-items-center"
        style="padding-right: 32px"
      >
        <a-space style="gap: 16px">
          <span v-if="videoToPlay?.fileName">
            {{ `${videoToPlay?.fileName} - ${taskName} ${modelVersion}` }}
          </span>
          <span v-if="translationLanguage" class="d-flex align-items-center">
            <a-typography-text class="bold">
              Select Language:
            </a-typography-text>
            <a-select
              :value="selectedLang"
              :options="languageOptions"
              class="w-20 mx-2 mt-1"
              size="small"
              @change="(value) => setSelectedLanguage(value)"
            />
          </span>
        </a-space>
        <a-space class="ml-auto" v-if="!isInterestingCycles">
          <a-button
            type="primary"
            :disabled="prevVideoButtonDisabled"
            @click="handleVideoChange(-1)"
          >
            Previous
          </a-button>
          <a-button
            type="primary"
            :disabled="nextVideoButtonDisabled"
            @click="handleVideoChange(1)"
          >
            Next
          </a-button>
        </a-space>
      </div>
    </template>
    <recorded-inference
      :key="videoToPlay"
      :is-fetching-video-url="isFetchingVideoUrl"
      :video="videoToPlay"
      :steps-list="stepsList"
      :comments="comments"
      @handleCycleCount="handleCycleCount"
      @updateVideoInList="updateVideoInList"
      @loading="(val) => (isFetchingVideoUrl = val)"
      @archivedCycleCount="archivedCycleCount"
      @toggleCycleStatus="toggleCycleStatus"
      @updateVideoListCount="updateVideoListCount"
    />
  </a-modal>

  <a-modal
    v-model:visible="showValidationVideoModal"
    centered
    :title="videoToPlay?.fileName"
    destroy-on-close
    width="40vw"
    @cancel="handleVideoModalClose"
  >
    <template #footer>
      <a-skeleton v-if="isFetchingVideoUrl" active />
      <a-row v-else>
        <a-col span="10">
          <a-descriptions
            id="trace-page-single-video-details"
            title="Details"
            class="text-start"
          >
            <a-descriptions-item
              id="trace-page-single-video-cycle-id"
              label="Cycle Identifier"
              span="3"
            >
              <a-tag color="blue">
                {{ videoToPlay?.cycle_identifier }}
              </a-tag>
            </a-descriptions-item>
            <a-descriptions-item
              id="trace-page-single-video-cycle-time"
              label="Cycle Time"
            >
              <a-tag color="blue">
                {{ videoToPlay?.cycle_time }}
              </a-tag>
            </a-descriptions-item>
          </a-descriptions>
        </a-col>

        <a-col span="14" class="d-flex flex-column">
          <h6 class="missed-steps-title">Missed steps</h6>
          <div
            id="trace-page-single-video-details"
            class="missed-steps-container text-start"
          >
            <a-tag
              v-for="step in videoToPlay?.missedStepsName"
              :key="step"
              color="error"
            >
              {{ step }}
            </a-tag>
            <a-tag v-if="videoToPlay?.missedStepsName?.length === 0">
              No missed steps
            </a-tag>
          </div>
        </a-col>
      </a-row>
    </template>
    <div
      v-if="isFetchingVideoUrl"
      class="w-100 d-flex"
      style="height: 350px !important"
    >
      <a-spin class="m-auto" size="large" />
    </div>
    <video
      v-else
      id="trace-page-single-video-play"
      class="w-100"
      controls
      :src="videoToPlay?.fileURL"
    />
  </a-modal>

  <a-modal
    id="trace-video-comments-modal"
    v-model:visible="showCommentsModal"
    title="Comments"
    :footer="null"
    centered
    destroy-on-close
  >
    <comments
      :comments="comments"
      :selected-video="selectedVideo"
      :isfetching-video-url="isFetchingVideoUrl"
      @updateVideo="updateVideoInList"
      @updateVideoListCount="updateVideoListCount"
    />
  </a-modal>

  <a-card
    id="trace-page-videos-card"
    class="filter-card"
    :loading="isLoading"
    :tab-list="traceCycleTabs"
    :active-tab-key="activeCycleTab"
    :body-style="{
      height: '55vh',
      overflowY: 'auto',
    }"
    @tabChange="handleCycleTabChange"
  >
    <template v-if="isInterestingCycles" #title>
      <div class="d-flex align-items-center">
        <a-typography-title :level="5" class="my-0">
          {{ title }}: &nbsp;
          <a-tag id="trace-page-videos-count" color="blue">
            {{ totalVideos }}
          </a-tag>
        </a-typography-title>
      </div>
    </template>

    <template #tabBarExtraContent>
      <a-space size="middle">
        <a-select
          v-if="activeCycleTab === 'bad_cycles'"
          :value="badCycleType"
          style="width: 170px"
          :options="badCycleOptions"
          @change="handleChangeBadCycle"
        />
        <div class="d-flex align-items-center">
          <a-typography-title :level="5" class="my-0">
            {{ title }}: &nbsp;
            <a-tag id="trace-page-videos-count" color="blue">
              {{ totalVideos }}
            </a-tag>
          </a-typography-title>
        </div>
      </a-space>
    </template>

    <a-row id="trace-page-video-list" :gutter="[32, 24]">
      <video-component
        v-for="(video, index) in list"
        :key="index"
        :index="index"
        :video="video"
        :cycle="cycle"
        :is-interesting-cycles="isInterestingCycles"
        :go-to-trace-tab="goToTraceTab"
        @handleCopyUrl="copyVideoUrl"
        @handlePlay="handlePlay"
        @updateVideoUrl="updateVideoUrl"
        @bookMarkVideo="bookMarkVideo"
        @updateVideo="updateVideoInList"
        @showComments="fetchComments"
        @handleUpdateVideo="handleUpdateVideo"
        @archivedCycleCount="archivedCycleCount"
        @toggleCycleStatus="toggleCycleStatus"
      />
    </a-row>
  </a-card>
</template>

<script>
import parseTaskList from 'src/components/shared/Helpers/parseTaskList';
import cycleTypes from 'src/config/cycle-type-config';
import TaskRecordService from 'src/services/taskRecord';
import { useToast } from 'vue-toastification';
import { mapActions, mapGetters } from 'vuex';
import TelemetryService from '../../../../services/telemetry';
import VideoService from '../../../../services/videos';
import RecordedInference from '../../../shared/Components/RecordedInference.vue';
import Comments from './Comments.vue';
import VideoComponent from './VideoDetails.vue';

export default {
  components: {
    RecordedInference,
    VideoComponent,
    Comments,
  },
  props: {
    totalVideos: { type: Number, default: 0, required: true },
    title: { type: String, default: 'Total Videos' },
    cycle: { type: String, default: 'all_cycles', required: true },
    list: { type: Array, default: () => [], required: true },
    fetchPresignedUrl: { type: Function, default: () => {}, required: true },
    getVideoS3Details: { type: Function, default: () => {}, required: true },
    isInterestingCycles: { type: Boolean, default: false },
    currentPage: { type: Number, default: -1 },
    pageSize: { type: Number, default: 0 },
    isLoading: { type: Boolean, default: false },
  },
  emits: [
    'updateVideoUrl',
    'updateVideo',
    'handlePageChange',
    'handleCycleCount',
    'archivedCycleCount',
    'updateVideoListCount',
    'bookMarkVideo',
  ],
  setup: () => {
    const toast = useToast();

    return { toast, cycleTypes };
  },
  data() {
    return {
      show: false,
      selectedVideo: {},
      videoToPlay: null,
      flag: false,
      showVideoModal: false,
      showValidationVideoModal: false,
      isFetchingVideoUrl: false,
      showCommentsModal: false,
      comments: [],
      stepsList: [],
      activeCycleTab: this.cycleTypes['All Cycles'],
      currentVideoIndex: 0,
      badCycleType: 'bad_cycles',
    };
  },
  computed: {
    ...mapGetters([
      'taskDetails',
      'organization',
      'taskName',
      'selectedLang',
      'translationLanguage',
      'selectedCycleType',
      'taskParameters',
      'selectedTask',
      'modelVersions',
    ]),

    modelVersion() {
      if (!this.videoToPlay) {
        return;
      }
      let version = this.modelVersions.find(
        (data) => data.id === this.videoToPlay.model_version
      )?.version_number;
      return version ? `(${version})` : '';
    },

    languageOptions() {
      return [
        { value: 'English', label: 'ENGLISH' },
        {
          value: `${this.translationLanguage}`,
          label: `${this.translationLanguage?.toUpperCase()}`,
        },
      ];
    },

    nextVideoButtonDisabled() {
      const currentPageIndex =
        (this.currentPage - 1) * this.pageSize + (this.currentVideoIndex + 1);
      return currentPageIndex >= this.totalVideos;
    },

    prevVideoButtonDisabled() {
      const currentPageIndex =
        (this.currentPage - 1) * this.pageSize + (this.currentVideoIndex + 1);
      return currentPageIndex <= 1;
    },

    traceCycleTabs() {
      return this.isInterestingCycles
        ? []
        : Object.entries(this.cycleTypes)?.map(([label, key]) => ({
            tab: label,
            key,
          }));
    },

    badCycleOptions() {
      return [
        { label: 'All Bad Cycles', value: 'bad_cycles' },
        {
          label: 'Timed out cycles',
          value: 'timed_out_cycles',
          disabled: this.cycleMaxTime ? false : true,
        },
        { label: 'Non-timed out cycles', value: 'filtered_timed_out_cycles' },
      ];
    },

    cycleMaxTime() {
      return this.taskParameters?.telemetryParams?.maximum_cycle_time;
    },
  },
  watch: {
    taskDetails(value) {
      this.stepsList = parseTaskList(value);
    },
    selectedLang(value) {
      this.setStepTranslations(value);
    },
    videoToPlay(video) {
      if (video && video?.fileName) {
        this.fetchComments(false, this.currentVideoIndex);
      }
    },

    activeCycleTab(value) {
      this.$emit('syncActiveCycleTab', value);
    },
  },
  mounted() {
    this.stepsList = parseTaskList(this.taskDetails);
  },
  methods: {
    ...mapActions([
      'setSelectedLanguage',
      'setStepTranslations',
      'setSelectedCycleType',
    ]),

    handleCycleTabChange(key) {
      let cycleTypeKey = key;
      this.activeCycleTab = key;
      if (key === 'bad_cycles') cycleTypeKey = this.badCycleType;
      this.setSelectedCycleType(cycleTypeKey);
      this.$emit('handlePageChange', 1);
    },

    handleChangeBadCycle(value) {
      this.badCycleType = value;
      this.setSelectedCycleType(value);
      this.$emit('handlePageChange', 1);
    },

    async handleVideoChange(newIndex) {
      this.currentVideoIndex += newIndex;
      let totalPages = Math.ceil(this.totalVideos / this.pageSize);
      let newPage = this.currentPage;

      if (
        this.currentVideoIndex >= this.pageSize &&
        this.currentPage < totalPages
      ) {
        newPage = this.currentPage + 1;
        this.handleVideoPageChange(newPage);
        return;
      }

      if (this.currentVideoIndex + 1 <= 0 && this.currentPage > 1) {
        newPage = this.currentPage - 1;
        this.handleVideoPageChange(newPage);
        return;
      }
      this.onCurrentImageIndexChanged();
    },

    handleVideoPageChange(newPage) {
      if (!newPage) return;
      // go to first video of next page
      if (this.currentPage < newPage) {
        this.currentVideoIndex = 0;
        this.$emit(
          'handlePageChange',
          newPage,
          this.onCurrentImageIndexChanged
        );
      }
      // go to last video of previous page
      if (this.currentPage > newPage) {
        this.currentVideoIndex = this.pageSize - 1;
        this.$emit(
          'handlePageChange',
          newPage,
          this.onCurrentImageIndexChanged
        );
      }
      // setTimeout(() => this.onCurrentImageIndexChanged(), 2200);
    },

    onCurrentImageIndexChanged() {
      if (this.list[this.currentVideoIndex]) {
        this.videoToPlay = null;
        const newVideo = this.list[this.currentVideoIndex];
        this.handleGetVideoData(newVideo);
      }
    },

    async getFileUrl(video, isPredictionFile = false) {
      const { bucket, filePath } = this.getVideoS3Details(
        video,
        isPredictionFile
      );
      return await this.fetchPresignedUrl(bucket, filePath);
    },

    goToTraceTab(video) {
      let { href } = this.$router.resolve({
        path: `trace/${video.id}`,
        query: {
          taskId: this.selectedTask,
          cycle_id: video.cycle_id,
        },
      });
      window.open(href, '_blank');
    },

    copyVideoUrl(video) {
      let { href } = this.$router.resolve({
        path: `trace/${video.id}`,
        query: {
          taskId: this.selectedTask,
          cycle_id: video.cycle_id,
        },
      });

      // Construct the full URL including the domain
      const fullUrl = `${window.location.origin}${href}`;

      // Copy the full URL to clipboard
      navigator.clipboard
        .writeText(fullUrl)
        .then(() => {
          // Optionally, notify the user that the URL has been copied
          this.toast.success('URL copied!');
        })
        .catch((err) => {
          console.error('Failed to copy the URL: ', err);
        });
    },

    async handlePlay(data) {
      const { video, index } = data;
      this.videoToPlay = null;
      this.currentVideoIndex = index;
      // if (video.device_id === this.organization)
      //   this.showValidationVideoModal = true;
      // else this.showVideoModal = true;
      this.showVideoModal = true;
      await this.handleGetVideoData(video);
    },

    async handleGetVideoData(video) {
      if (!video.fileURL || !video.per_frame_prediction_file_url) {
        this.isFetchingVideoUrl = true;
        const [video_url, preds_url] = await Promise.all([
          this.getFileUrl(video),
          this.getFileUrl(video, true),
        ]);
        const videoFPS = await this.getVideoFPS(video);
        this.isFetchingVideoUrl = false;
        if ([video_url, preds_url].includes('error')) {
          this.toast.error('Error occure while fetching url!');
          return;
        }

        video = {
          ...video,
          fileURL: video_url,
          per_frame_prediction_file_url: preds_url,
          isVideoExists: true,
          fps: videoFPS,
        };
      }
      this.videoToPlay = { ...video };
    },

    getVideoFPS(video) {
      return new Promise(async (resolve) => {
        let payload = { id: video.id };
        const [error, res] = await TaskRecordService.getVideoMetaData(
          payload,
          false
        );

        if (error) {
          console.log({ error });
          return resolve(30);
        }

        if (res && !Object.keys(res)?.length) {
          return resolve(30);
        }

        const { data } = res;
        let { fps } = data;
        return resolve(fps);
      });
    },

    handleVideoModalClose() {
      const videoParams = {
        videoName: this.videoToPlay.fileName,
        updatedObj: this.videoToPlay,
      };
      this.updateVideoInList(videoParams);
    },

    updateVideoUrl(videoName, url) {
      this.$emit('updateVideoUrl', videoName, url);
    },

    async fetchComments(modal = true, videoIndex = 0) {
      const video = this.list[videoIndex];
      let data = {
        comments: [],
        videoRecord: video,
        show: modal,
      };
      if (!video.entity_id) {
        this.handleShowCommentsModal(data);
        return;
      }
      const comment_response = await VideoService.getCommentsForEntity(
        video.entity_id
      );
      data['comments'] = [...comment_response];
      this.handleShowCommentsModal(data);
    },

    updateVideoListCount(videoIndex, commentLength) {
      this.$emit('updateVideoListCount', videoIndex, commentLength);
    },

    handleShowCommentsModal(data) {
      const { comments, videoRecord, show } = data;
      this.comments = comments;
      this.selectedVideo = videoRecord;
      if (show) this.showCommentsModal = true;
    },

    async toggleCycleStatus({ video, isGoodCycle }) {
      const [error] = await TelemetryService.revertCycle(video.cycle_id, {
        is_good_cycle: isGoodCycle,
      });
      if (error) {
        this.toast.error('Unable to update the cycle status!');
        return;
      }
      this.$emit('handleCycleCount', isGoodCycle);
      this.$emit('updateVideo', {
        videoName: video.fileName,
        updatedObj: { is_good_cycle: isGoodCycle },
      });
    },

    async archivedCycleCount(isGoodCycle) {
      this.$emit('archivedCycleCount', isGoodCycle);
      this.handleVideoModalClose();
      this.showVideoModal = false;
    },

    bookMarkVideo(params) {
      this.$emit('bookMarkVideo', params);
    },

    handleUpdateVideo(params) {
      this.$emit('updateVideo', {
        videoName: params.fileName,
        updatedObj: params.updatedObj,
      });
    },

    updateVideoInList(params) {
      if (this.videoToPlay) {
        this.videoToPlay = { ...this.videoToPlay, ...params.updatedObj };
      }
    },
  },
};
</script>
<style>
.inference-modal .ant-modal-header {
  padding: 14px;
}
</style>
<style scoped>
.missed-steps-title {
  width: 100% !important;
  text-align: start;
  font-weight: bold;
}
.filter-card {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.missed-steps-container {
  height: 15vh;
  width: 100%;
  overflow-y: auto;
  padding: 0.5em;
  border: 1px solid #f1f1f1;
}
</style>
